define(['app'], function(app) {


  var productQuantityInput = function() {
    var component = {};

    var _config = {
      classes: {
        showClass: 'showClass',
        hideClass: 'hideClass',
        inputError: 'productQuantityInput_error'
      },
      selectors: {
        productQuantityInputBlock: '[data-product-quantity-input-block]',
        inputMessageBlock: '[data-message-block]',
        inputMessageBlockQuantities: '[data-message-block-quantities]',
        inputMessageBasketLimit: '[data-message=basketLimit]',
        inputMessageBasketSingleItem: '[data-message=basketSingleItem]',
        inputMessageBasketMultipleItems: '[data-message=basketMultipleItems]',
        inputSelector: '[data-quantity-input]',
        increaseSelector: '[data-quantity-button=increase]',
        decreaseSelector: '[data-quantity-button=decrease]',
        maximumQuantityMessageSelector: '[data-quantity-message=maximumValue]',
        maximumQuantityParentSelector: '[data-quantity-message=maximumValueParent]',
        currentQuantityMessageSelector: '[data-quantity-message=currentQuantityInBasket]',
        currentQuantityMessageParentSelector: '[data-quantity-message=currentQuantityInBasketParent]',
        visibilityWrapper: '[data-product-quantity-visibilty-wrapper]'
      },
      subscribeChannels: {
        quantity: 'productQuantityInput/quantityData',
        disable: 'productQuantityInput/disable',
        updateVisibility: 'productQuantity/updateVisbilty',
        productQuantity: 'productQuantity/productQuantity',
        productAdded: 'addedToBasketModal/productAdded'
      },
      quantityMaxLength: 2,
      quantityBoxMaximumItems: 99,
      quantityMaximumAllowedLength: 5000
    };

    var _init = function(element) {
      component.element = element;

      if (component.element) {
        component.productQuantityInputBlock = component.element.querySelector(component.config.selectors.productQuantityInputBlock);
        component.quantityInput = component.element.querySelector(component.config.selectors.inputSelector);
        component.increaseButton = component.element.querySelector(component.config.selectors.increaseSelector);
        component.decreaseButton = component.element.querySelector(component.config.selectors.decreaseSelector);
        component.maximumQuantityMessage = component.element.parentElement.querySelector(component.config.selectors.maximumQuantityMessageSelector);
        component.maximumQuantityParent = component.element.parentElement.querySelector(component.config.selectors.maximumQuantityParentSelector);
        component.currentQuantityMessage = component.element.parentElement.querySelector(component.config.selectors.currentQuantityMessageSelector);
        component.currentQuantityMessageParents = component.element.parentElement.querySelectorAll(
          component.config.selectors.currentQuantityMessageParentSelector
        );
        component.messageBlock = component.element.parentElement.querySelector(component.config.selectors.inputMessageBlock);
        component.messageBlockQuantities = component.element.parentElement.querySelector(component.config.selectors.inputMessageBlockQuantities);
        component.inputMessageBasketLimit = component.element.parentElement.querySelector(component.config.selectors.inputMessageBasketLimit);
        component.inputMessageBasketSingleItem = component.element.parentElement.querySelector(component.config.selectors.inputMessageBasketSingleItem);
        component.inputMessageBasketMultipleItems = component.element.parentElement.querySelector(component.config.selectors.inputMessageBasketMultipleItems);
        component.visibilityWrapper = document.querySelector(component.config.selectors.visibilityWrapper);

        component.quantityValue = 1;
        component.addEventListeners();
        component.publishQuantity();

        var quantityData = {
          numberOfItemsInBasket: component.currentQuantityMessage.textContent,
          maximumNumberOfItemsToAdd: component.maximumQuantityMessage.textContent
        };
        component.showQuantityInputMessage(quantityData);
        component.subscribe();
      }

      return component;
    };

    const _subscribe = () => {
      app.subscribe(component.config.subscribeChannels.updateVisibility, component.updateVisbilty);
      app.subscribe(component.config.subscribeChannels.productQuantity, component.showQuantityInputMessage);
      app.subscribe(component.config.subscribeChannels.productAdded, component.updateQuantityInBasket);
      app.subscribe(component.config.subscribeChannels.disable, component.updateComponentState);
    };

    var _addEventListeners = function() {
      component.quantityInput.addEventListener('input', component.validateLength);
      component.quantityInput.addEventListener('change', component.changeEvent);
      component.increaseButton.addEventListener('click', component.increaseQuantity);
      component.decreaseButton.addEventListener('click', component.decreaseQuantity);
    };

    var _validateLength = function(event) {
      var eventElement = event.target;

      if (eventElement.value.length > component.config.quantityMaxLength) {
        eventElement.value = eventElement.value.substring(0, component.config.quantityMaxLength);
      }
    };

    var _publishQuantity = function() {
      app.publish(component.config.subscribeChannels.quantity, {
        value: component.quantityValue,
        max: component.quantityMaximum,
        inBasket: component.quantityInBasket,
      }, true);
    };

    var _updateQuantityValue = function() {
      component.quantityValue = component.parseQuantityValue(component.quantityInput.value);
    };

    var _increaseQuantity = function() {
      component.updateQuantityValue();
      var value = component.quantityValue;

      if (value++ < component.quantityInput.max) {
        component.quantityValue = value;
        component.quantityInput.value = value;
        component.publishQuantity();
      }

      component.handleQuantityButtons();
      component.showMaximumQuantityMessage();
      component.updateAddToBasketButtonState();
    };

    var _decreaseQuantity = function() {
      component.updateQuantityValue();
      var value = component.quantityValue;

      if (value-- > component.quantityInput.min) {
        component.quantityValue = value;
        component.quantityInput.value = value;
        component.publishQuantity();
      }

      component.handleQuantityButtons();
      component.showMaximumQuantityMessage();
      component.updateAddToBasketButtonState();
    };

    var _changeEvent = function() {
      component.updateQuantityValue();

      if (component.quantityValue + component.quantityInBasket > component.quantityMaximum) {
        component.quantityValue = component.quantityMaximum - component.quantityInBasket;
        component.quantityInput.value = component.quantityValue;
      }
      component.publishQuantity();
      component.handleQuantityButtons();
      component.updateAddToBasketButtonState();
      component.showMaximumQuantityMessage();
    };

    var _handleQuantityButtons = function() {
      if (component.quantityInBasket !== 0 && component.quantityInBasket === component.quantityMaximum) {
        component.inputBoxFeedback('error');
        component.disableQuantityInput();
        return;
      }

      component.inputBoxFeedback('default');
      component.quantityInput.disabled = false;

      if (
        component.quantityValue + component.quantityInBasket >= component.quantityMaximum ||
        component.quantityValue >= component.config.quantityBoxMaximumItems
      ) {
        component.increaseButton.disabled = true;
        component.addLabelledByToButton(component.increaseButton);
        component.inputBoxFeedback('error');
      } else {
        component.increaseButton.disabled = false;
        component.removeLabelledByFromButton(component.increaseButton);
        component.inputBoxFeedback('default');
      }

      component.quantityValue <= 1 ? (component.decreaseButton.disabled = true) : (component.decreaseButton.disabled = false);
    };

    var _showMaximumQuantityMessage = function() {
      if (component.quantityMaximum === component.config.quantityMaximumAllowedLength || component.quantityMaximum === 0 || (component.quantityMaximum > component.quantityValue && component.quantityInBasket === 0)) {
        app.element.addClass(component.config.classes.hideClass, component.maximumQuantityParent);
        app.element.removeClass(component.config.classes.showClass, component.maximumQuantityParent);
      } else {
        app.element.removeClass(component.config.classes.hideClass, component.maximumQuantityParent);
        app.element.addClass(component.config.classes.showClass, component.maximumQuantityParent);
      }
    };

    var _parseQuantityValue = function(value) {
      var parsedValue = parseInt(value, 10);

      if (isNaN(parsedValue)) {
        component.quantityInput.value = 1;
        return 1;
      }
      return parsedValue;
    };

    var _updateAddToBasketButtonState = function() {
      component.quantityValue = parseInt(component.quantityValue, 10);
      component.quantityInBasket = parseInt(component.quantityInBasket, 10);
      component.quantityMaximum = parseInt(component.quantityMaximum, 10);
    };

    var _disableQuantityInput = function() {
      component.quantityInput.disabled = true;
      component.increaseButton.disabled = true;
      component.decreaseButton.disabled = true;
    };

    var _updateQuantityInBasket = function(modalData) {

      component.quantityInBasket += modalData.productQuantity;

      var quantityInputData = {
        numberOfItemsInBasket: component.quantityInBasket,
        maximumNumberOfItemsToAdd: component.quantityMaximum
      };

      component.showQuantityInputMessage(quantityInputData);
    };

    var _showQuantityInputMessage = function(quantityInputData) {
      component.quantityMaximum = quantityInputData.maximumNumberOfItemsToAdd === null || isNaN(quantityInputData.maximumNumberOfItemsToAdd) || quantityInputData.maximumNumberOfItemsToAdd === ''
        ? component.config.quantityMaximumAllowedLength
        : parseInt(quantityInputData.maximumNumberOfItemsToAdd);

      component.quantityInBasket = quantityInputData.numberOfItemsInBasket === null || isNaN(quantityInputData.numberOfItemsInBasket) || quantityInputData.numberOfItemsInBasket === ''
        ? 1
        : parseInt(quantityInputData.numberOfItemsInBasket);


      if ((component.quantityMaximum - component.quantityInBasket > 0) && (component.quantityValue + component.quantityInBasket > component.quantityMaximum)) {
        if (component.quantityMaximum === component.quantityInBasket) {
          component.quantityValue = 1;
          component.quantityInput.value = 1;
        } else {
          component.quantityValue = component.quantityMaximum - component.quantityInBasket;
          component.quantityInput.value = component.quantityValue;
        }
      }

      component.handleQuantityButtons();
      component.updateAddToBasketButtonState();

      if (component.quantityMaximum < component.config.quantityMaximumAllowedLength) {
        app.element.addClass(component.config.classes.showClass, component.maximumQuantityParent);
      }

      component.maximumQuantityMessage.textContent = component.quantityMaximum;
      component.currentQuantityMessage.textContent = component.quantityInBasket;

      component.quantityInBasket > 0 && component.quantityMaximum < component.config.quantityMaximumAllowedLength
        ? app.element.addClass(component.config.classes.showClass, component.messageBlockQuantities)
        : app.element.removeClass(component.config.classes.showClass, component.messageBlockQuantities);

      if (component.quantityInBasket > 1) {
        app.element.removeClass(component.config.classes.showClass, component.inputMessageBasketSingleItem);
        app.element.addClass(component.config.classes.showClass, component.inputMessageBasketMultipleItems);
      } else if (component.quantityInBasket === 1) {
        app.element.removeClass(component.config.classes.showClass, component.inputMessageBasketMultipleItems);
        app.element.addClass(component.config.classes.showClass, component.inputMessageBasketSingleItem);
      }

      component.showMaximumQuantityMessage();
      component.publishQuantity();
    };

    var _checkSubscribeMessages = function(subscription, callback) {
      if (subscription.messages.length > 0) {
        var lastItem = subscription.messages.slice(-1)[0];
        callback(lastItem);
      }
    };

    var _inputBoxFeedback = function(type) {
      type === 'error'
        ? app.element.addClass(component.config.classes.inputError, component.productQuantityInputBlock)
        : app.element.removeClass(component.config.classes.inputError, component.productQuantityInputBlock);
    };

    const _addLabelledByToButton = function (button) {
      component.messageBlock.id && app.element.setAttribute('aria-labelledby', component.messageBlock.id, button);
    };

    const _removeLabelledByFromButton = function (button) {
      app.element.removeAttribute('aria-labelledby', button);
    };


    var _updateVisbilty = function(shown) {
      if (!component.visibilityWrapper) {
        return;
      }

      component.visibilityWrapper.setAttribute('data-show', '' + !!shown);
    };

    const _updateComponentState = (disable) => {
      if (disable) {
        component.element.classList.add('disabled');
      } else {
        component.element.classList.remove('disabled');
      }
    };

    component.init = _init;
    component.config = _config;
    component.addEventListeners = _addEventListeners;
    component.validateLength = _validateLength;
    component.publishQuantity = _publishQuantity;
    component.updateQuantityValue = _updateQuantityValue;
    component.increaseQuantity = _increaseQuantity;
    component.decreaseQuantity = _decreaseQuantity;
    component.parseQuantityValue = _parseQuantityValue;
    component.changeEvent = _changeEvent;
    component.showQuantityInputMessage = _showQuantityInputMessage;
    component.disableQuantityInput = _disableQuantityInput;
    component.updateAddToBasketButtonState = _updateAddToBasketButtonState;
    component.showMaximumQuantityMessage = _showMaximumQuantityMessage;
    component.handleQuantityButtons = _handleQuantityButtons;
    component.updateQuantityInBasket = _updateQuantityInBasket;
    component.checkSubscribeMessages = _checkSubscribeMessages;
    component.inputBoxFeedback = _inputBoxFeedback;
    component.addLabelledByToButton = _addLabelledByToButton;
    component.removeLabelledByFromButton = _removeLabelledByFromButton;
    component.updateVisbilty = _updateVisbilty;
    component.updateComponentState = _updateComponentState;
    component.subscribe = _subscribe;

    return component;
  };

  return productQuantityInput;
});
